import React from 'react';
import PropTypes from 'prop-types';
import { rem, math, getContrast } from 'polished';
import styled, { css } from 'styled-components';

import Row from '../../components/Row';
import Col from '../../components/Col';

import theme, { breakpoint } from '../../theme';
import { SectionTitle } from '../../utils/GlobalFormatting';

const Content = styled(Row)`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  width: 100%;

  ${(props) => {
    return props.inverted
      ? css`
          color: ${props.theme.palette.text.negative};

          /*  ${SectionTitle} {
            color: ${props.theme.palette.text.negative};
          } */
        `
      : css`
          color: ${props.theme.palette.text.primary};
        `;
  }}
`;

const Intro = styled.div`
  position: relative;
  margin: 0 auto ${rem(32)};
  max-width: ${(props) => props.theme.grid.maxWidthText};
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  min-height: ${rem(350)};
  padding-top: ${rem(60)};
  padding-bottom: ${rem(60)};

  ${breakpoint('large')} {
    min-height: ${rem(450)};
  }

  ${Content} {
    ${({ centered }) =>
      centered
        ? css`
            justify-content: center;
            text-align: center;
            max-width: ${(props) => props.theme.grid.maxWidthText};
          `
        : css`
            max-width: ${(props) => props.theme.grid.maxWidth};
          `}
  }
`;

const Column = styled(Col)`
  margin: 0 auto ${rem(20)};
  width: 100%;
`;

const Columns = styled(Row)`
  /* width: 100%; */
  flex-grow: 1;
  margin-left: ${(props) => math(`${props.theme.spacing.columnGutter} * -1`)};
  margin-right: ${(props) => math(`${props.theme.spacing.columnGutter} * -1`)};

  ${Column} {
    ${({ count }) => {
      switch (count) {
        case 1:
        default:
          return css`
            width: 100%;
          `;
        case 2:
          return css`
            ${breakpoint('large')} {
              width: 50%;
            }
          `;
        case 3:
          return css`
            ${breakpoint('mediumlarge')} {
              width: 33%;
            }

            ${breakpoint('large')} {
              width: 33%;
            }
          `;
        case 4:
          return css`
            ${breakpoint('mediumlarge')} {
              width: 50%;
            }

            ${breakpoint('large')} {
              width: 25%;
            }
          `;
      }
    }}
  }
`;

const Children = styled.div`
  > :last-child {
    margin-bottom: 0;
  }
`;

function ColumnsSection({
  title,
  columns,
  backgroundColor,
  centered,
  animated,
  withIntro,
  content,
}) {
  if (!columns) {
    return null;
  }

  const salProps = {
    'data-sal': 'slide-up',
    'data-sal-duration': '450',
    'data-sal-delay': '25',
    'data-sal-easing': 'easeOutExpo',
  };

  const columnCount = columns.length;

  const invertContent =
    backgroundColor &&
    getContrast(backgroundColor, theme.palette.text.primary) < 2.1;

  return (
    <Container
      style={backgroundColor ? { backgroundColor } : {}}
      centered={centered && columnCount === 1}
    >
      <Content
        inverted={invertContent}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(animated ? salProps : undefined)}
      >
        {(title || withIntro) && (
          <Col>
            {title && (
              <SectionTitle as="h3" centered={withIntro}>
                {title}
              </SectionTitle>
            )}
            {withIntro && (
              <Intro
                dangerouslySetInnerHTML={{ __html: content }} // eslint-disable-line react/no-danger
              />
            )}
          </Col>
        )}
        <Columns count={columnCount}>
          {columns.map((column, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Column key={`column-${index}`}>
              <Children
                dangerouslySetInnerHTML={{ __html: column.content }} // eslint-disable-line react/no-danger
              />
            </Column>
          ))}
        </Columns>
      </Content>
    </Container>
  );
}

ColumnsSection.propTypes = {
  title: PropTypes.node,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  backgroundColor: PropTypes.string,
  centered: PropTypes.bool,
  animated: PropTypes.bool,
  withIntro: PropTypes.bool,
  content: PropTypes.node,
};

ColumnsSection.defaultProps = {
  title: null,
  backgroundColor: null,
  centered: false,
  animated: false,
  withIntro: false,
  content: undefined,
};

export default ColumnsSection;
