import React from 'react';
import PropTypes from 'prop-types';
import { darken } from 'polished';
// import styled from 'styled-components';

import theme from '../../theme';

// const Root = styled.div`
//   ${({ error, theme }) =>
//     error && `border-color: ${theme.palette.colors.error};`}
// `;

function LogoTag({ color, size, ...other }) {
  const width = size * (24 / 22);
  const height = size;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 22"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      <path
        fill={color}
        d="M23.46 0H0v1l9.86 9.32L.3 17.78l.61.79 9.69-7.55 5.34 5.05-3.86 3.19.64.77 3.95-3.27 5.47 5.17.69-.72-5.37-5.08 3.88-3.22-.64-.77-4 3.3-5.32-5L23.46 1V0zM10.68 9.71L1.46 1h20.41L10.68 9.71z"
      />
    </svg>
  );
}

LogoTag.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

LogoTag.defaultProps = {
  size: 26,
  color: darken(0.1, theme.palette.background.content),
};

export default LogoTag;
