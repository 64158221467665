import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import styled from 'styled-components';

import Row from '../../components/Row';
import Col from '../../components/Col';
import ArticleItem from '../../components/ArticleItem';
import { SectionTitle } from '../../utils/GlobalFormatting';

import { breakpoint } from '../../theme';

// import LinkUtil from '../../utils/link';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: 0 auto ${rem(40)};

  ${breakpoint('large')} {
    margin-bottom: ${rem(50)};
  }
`;

const Content = styled(Row)`
  position: relative;
  display: flex;
  flex-flow: row wrap;
`;

const ArticleCol = styled(Col)`
  margin: 0 0 ${rem(10)};
  width: 100%;

  ${breakpoint('large')} {
    width: 50%;
  }
`;

function ArticlesSection({ data, allArticles }) {
  const { title, categories: category } = data;

  // If multiple categories
  // const groupedItems = categories.map((cat) => ({
  //   ...cat,
  //   posts: allArticles.edges
  //     .filter(({ node: item }) =>
  //       item.categories.find(
  //         // eslint-disable-next-line camelcase
  //         (postCat) => postCat.term_id === cat,
  //       ),
  //     )
  //     .map(({ node: item }) => item),
  // }));

  const categories = [];
  if (category) {
    categories.push(category);
  }

  // Single category
  const groupedItems = categories.map((cat) => ({
    ...cat,
    posts: allArticles.edges
      .filter(({ node: item }) =>
        item.categories.find(
          // eslint-disable-next-line camelcase
          (postCat) => postCat.wordpress_id === cat.term_id,
        ),
      )
      .map(({ node: item }) => item),
  }));

  return (
    <Container>
      <Content>
        {title && (
          <Col>
            <SectionTitle as="h3">{title}</SectionTitle>
          </Col>
        )}

        {groupedItems.map((cat) =>
          cat.posts.map((item) => (
            <ArticleCol key={item.id}>
              <ArticleItem data={item} />
            </ArticleCol>
          )),
        )}
      </Content>
    </Container>
  );
}

ArticlesSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  allArticles: PropTypes.oneOfType([PropTypes.object]),
};

ArticlesSection.defaultProps = {
  allArticles: [],
};
export default ArticlesSection;
