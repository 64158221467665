import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import Hero from '../../components/Hero';
import Button from '../../components/Button';

const Root = styled.div`
  position: relative;
  width: 100%;
`;

function HeroImage({ data, pageTitle }) {
  const { button, content, image } = data;
  return (
    <Root>
      <Hero
        preHeading={content.title_add_on}
        heading={content.title}
        image={image?.localFile}
        actions={
          button.link && (
            <Button link={`/${button.link}`} variant="fill">
              {button.text}
            </Button>
          )
        }
        tint
        big
        pageTitle={pageTitle}
      >
        {content.text}
      </Hero>
    </Root>
  );
}

HeroImage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageTitle: PropTypes.bool,
};

HeroImage.defaultProps = {
  pageTitle: false,
};

export default HeroImage;
