import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import styled from 'styled-components';
import { BgImage } from 'gbimage-bridge';

// import { GridContainer } from '../../components/Grid';
// import Row from '../../components/Row';
import Col from '../../components/Col';
// import LogoTag from '../../components/LogoTag';
import { DiscreteItem } from '../../components/Menu';
// import Image from '../../components/Image';

import { SectionTitle, StyledContent } from '../../utils/GlobalFormatting';

import { breakpoint } from '../../theme';

const Root = styled.div`
  position: relative;
  width: 100%;
  margin: ${rem(0)} auto 0;
  overflow-x: hidden;

  ${breakpoint('mediumlarge')} {
    display: flex;
  }
`;

const BlockContainer = styled.div`
  max-width: ${rem(510)};
  width: 90%;
  margin: ${rem(0)} auto 0;
  padding-top: ${rem(60)};
  padding-bottom: ${rem(60)};
`;

const Actions = styled.div`
  display: block;
  margin-top: ${rem(10)};
`;

const ContentCol = styled(Col)`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
`;

const ImageCol = styled(Col)`
  position: relative;
  padding: 0;
`;

const Image = styled(BgImage)`
  position: relative;
  padding-bottom: 66%;

  ${breakpoint('mediumlarge')} {
    padding-bottom: 81%;
  }
`;

function SplitSection({ title, children, reverse, image, button, animated }) {
  const salProps = {
    'data-sal': 'slide-up',
    'data-sal-duration': '450',
    'data-sal-delay': '25',
    'data-sal-easing': 'easeOutExpo',
  };

  const salPropsImage = {
    'data-sal': `slide-${reverse ? 'left' : 'right'}`,
    'data-sal-duration': '450',
    'data-sal-delay': '25',
    'data-sal-easing': 'easeOutExpo',
  };

  const sectionImage = getImage(image?.localFile);

  return (
    <Root
      style={
        reverse
          ? {
              flexDirection: 'row-reverse', // Only reverse on desktop
            }
          : {}
      }
    >
      <ImageCol
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(animated ? salPropsImage : undefined)}
      >
        {image && <Image image={sectionImage} />}
      </ImageCol>
      <ContentCol>
        <BlockContainer>
          <StyledContent
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(animated ? salProps : undefined)}
          >
            {title && <SectionTitle>{title}</SectionTitle>}
            <div
              dangerouslySetInnerHTML={{ __html: children }} // eslint-disable-line react/no-danger
            />
            {button.link && (
              <Actions>
                <DiscreteItem link={button.link}>{button.label}</DiscreteItem>
              </Actions>
            )}

            {/* <div style={{ marginTop: rem(30) }}>
              <LogoTag color={colors.greyLight} />
            </div> */}
          </StyledContent>
        </BlockContainer>
      </ContentCol>
    </Root>
  );
}

SplitSection.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  button: PropTypes.shape({
    link: PropTypes.string,
    label: PropTypes.string,
  }),
  reverse: PropTypes.bool,
  image: PropTypes.oneOfType([PropTypes.object]),
  animated: PropTypes.bool,
};

SplitSection.defaultProps = {
  title: null,
  children: null,
  button: {
    link: null,
    label: null,
  },
  reverse: false,
  image: null,
  animated: true,
};

export default SplitSection;
