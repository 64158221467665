import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import Row from '../../components/Row';
import Col from '../../components/Col';
import Button from '../../components/Button';
import { GridContainer } from '../../components/Grid';
import PackageItem from '../../components/PackageItem';
import TagFilter from '../../components/TagFilter';

import { SectionTitle } from '../../utils/GlobalFormatting';
import { breakpoint } from '../../theme';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: ${rem(60)};
  padding-bottom: ${rem(60)};
  margin: ${rem(0)} auto 0;
  border-top: 1px solid ${(props) => props.theme.palette.colors.greyLight};
`;

const Title = styled(SectionTitle)`
  /* color: inherit; */
`;

const Actions = styled.div`
  display: block;
  margin-top: ${rem(30)};
`;

const Filter = styled(Row)`
  width: 100%;
`;

const Packages = styled(Row)`
  width: 100%;
  margin-top: ${rem(18)};
`;

const PackageCol = styled(Col)`
  width: 100%;

  ${breakpoint('mediumlarge')} {
    width: 33.333%;
  }

  ${breakpoint('large')} {
    width: 33.333%;
  }
`;

function PackagesArchiveSection({ title, actions, categories }) {
  const { t } = useTranslation();
  const [activeFilter, setFilter] = useState(0);
  const maxItemCount = activeFilter === 0 ? 100 : 9;

  if (!categories) {
    return null;
  }

  const activeCategory = categories.find(
    (category) => activeFilter === category.termTaxonomyId,
  );

  const items =
    activeCategory?.packages.nodes ||
    categories.reduce(
      (allCategories, cat) => [
        ...allCategories,
        ...cat.packages.nodes.filter(
          (item) =>
            !allCategories.some((existingItem) => existingItem.id === item.id),
        ),
      ],
      [],
    );
  const sortedItems = items.sort((a, b) => a.menuOrder - b.menuOrder);

  const filterItems = categories.map((category) => ({
    id: category.termTaxonomyId,
    key: category.id,
    title: category.name,
  }));

  const packagesArchive = sortedItems.length > maxItemCount;

  return (
    <Root>
      <GridContainer>
        <Row style={{ width: '100%', maxWidth: rem(875) }}>
          <Col>
            <h5>{t('packagesTitle')}</h5>
            <Title>{title}</Title>
          </Col>
        </Row>
        {filterItems && (
          <Filter>
            <Col>
              <TagFilter
                onClick={setFilter}
                items={filterItems}
                active={activeFilter}
              />
            </Col>
          </Filter>
        )}
        {sortedItems && (
          <Packages>
            {sortedItems.slice(0, maxItemCount).map((item) => {
              return (
                <PackageCol key={item.id}>
                  <PackageItem
                    image={item.featuredImage?.node}
                    title={item.title}
                    text={item.excerpt}
                    subText={item.excerpt}
                    button={{ link: item.uri, text: t('readMore') }}
                  />
                </PackageCol>
              );
            })}
          </Packages>
        )}

        {actions && <Actions>{actions}</Actions>}

        {packagesArchive && (
          <Row style={{ textAlign: 'center', marginTop: rem(25) }}>
            <Col>
              <Button link={activeCategory?.uri}>{t('morePackages')}</Button>
            </Col>
          </Row>
        )}
      </GridContainer>
    </Root>
  );
}

PackagesArchiveSection.propTypes = {
  title: PropTypes.node.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.node,
};

PackagesArchiveSection.defaultProps = {
  categories: null,
  actions: null,
};

export default PackagesArchiveSection;
