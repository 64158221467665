import React from 'react';
import PropTypes from 'prop-types';
import { rem, rgba } from 'polished';
import styled from 'styled-components';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';

// import Image from '../Image';
import Link from '../Link';

import { breakpoint } from '../../theme';

const Thumbnail = styled(BgImage)`
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: 70%;
  overflow: hidden;
  height: 100%;
`;

const Inner = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  /* border-bottom: 1px solid ${(props) => props.theme.global.border.color}; */
  text-align: left;
  background-color: ${(props) => props.theme.palette.colors.lightBlack};
  /* box-shadow: 0 8px 18px 0 rgba(36, 36, 36, 0.25); */
`;

const Content = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: ${rem(16)} ${(props) => props.theme.spacing.gutter};
  background-color: ${(props) => rgba(props.theme.palette.colors.black, 0.58)};
  color: ${(props) => props.theme.palette.colors.white};
`;

const ThumbnailPlaceholder = styled.div`
  display: block;
  width: 100%;
  padding-bottom: 66%;
  background-color: ${(props) => props.theme.palette.colors.grey};
`;

const Title = styled.h3`
  display: block;
  width: 100%;
  margin-bottom: 0;
  font-size: ${rem(20)};
  color: inherit;
  text-align: center;

  ${breakpoint('large')} {
    font-size: ${rem(21)};
  }
`;

const SubText = styled.span`
  display: none;
  width: 100%;
  margin: ${rem(5)} 0 0;
  font-size: ${rem(15)};
  color: inherit;
  text-align: center;
  font-weight: ${(props) => props.theme.typography.weights.primary.normal};

  ${breakpoint('large')} {
    font-size: ${rem(16)};
  }
`;

const Root = styled.div`
  position: relative;
  width: 100%;
  transition: ${(props) =>
    `transform ${props.theme.global.transition.duration} ${props.theme.global.transition.timing}`};

  &:hover {
    ${SubText} {
      display: block;
    }
  }
`;

function ImageBoxItem(props) {
  const { image, title, subText, link } = props;

  const heroImage = getImage(image);
  // const heroImageSrc = getSrc(image);

  return (
    <Root>
      <Inner to={link} as={link ? Link : null}>
        {heroImage ? (
          <Thumbnail Tag="div" image={heroImage}>
            {/* <Image
              image={heroImage}
              fallback={{
                url: heroImageSrc
                alt: image.altText,
              }}
              alt={image.altText}
            /> */}
          </Thumbnail>
        ) : (
          <ThumbnailPlaceholder />
        )}

        <Content>
          <Title>{title}</Title>
          {subText && <SubText>{subText}</SubText>}
        </Content>
      </Inner>
    </Root>
  );
}

ImageBoxItem.propTypes = {
  title: PropTypes.node.isRequired,
  image: PropTypes.shape({
    localFile: PropTypes.oneOfType([PropTypes.object]),
    source_url: PropTypes.string,
    altText: PropTypes.string,
    title: PropTypes.string,
  }),
  subText: PropTypes.node,
  link: PropTypes.string.isRequired,
};

ImageBoxItem.defaultProps = {
  image: null,
  subText: null,
};

export default ImageBoxItem;
