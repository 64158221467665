import React from 'react';
import PropTypes from 'prop-types';
import { rem, getContrast, lighten } from 'polished';
import styled, { css } from 'styled-components';

import Row from '../../components/Row';
import Col from '../../components/Col';
import { GridContainer } from '../../components/Grid';
import ImageBoxItem from '../../components/ImageBoxItem';
import LogoTag from '../../components/LogoTag';

import theme, { breakpoint } from '../../theme';
import { SectionTitle } from '../../utils/GlobalFormatting';

const Root = styled.div`
  margin: 0;
  padding-top: ${rem(60)};
  padding-bottom: ${rem(60)};

  /* ${breakpoint('large')} {
    margin-bottom: ${rem(50)};
  } */
`;

const Content = styled(Row)`
  position: relative;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  margin: 0 auto ${rem(25)};
  max-width: ${rem(1050)};
  text-align: center;

  ${(props) => {
    return props.inverted
      ? css`
          color: ${props.theme.palette.text.negative};

          ${SectionTitle} {
            color: ${props.theme.palette.text.negative};
          }
        `
      : css`
          color: ${props.theme.palette.text.primary};
        `;
  }}
`;

const Column = styled(Col)`
  margin: 0 auto ${rem(20)};
  width: 100%;
`;

const Columns = styled(Row)`
  width: 100%;

  ${breakpoint('mediumlarge')} {
    flex-wrap: wrap;
    flex-direction: column;

    ${({ rowCount, itemHeight }) => css`
      max-height: ${rem(rowCount * itemHeight + 80)};
    `}
  }

  ${Column} {
    ${({ count, itemHeight }) => {
      switch (count) {
        case 1:
          return css`
            width: 100%;
          `;
        case 2:
          return css`
            ${breakpoint('large')} {
              width: 50%;
            }
          `;
        default:
          return css`
            ${breakpoint('mediumlarge')} {
              width: 33.333%;

              &:first-child,
              &:nth-child(7) {
                height: ${rem(itemHeight * 2)};

                & > * {
                  height: 100%;
                }
              }
            }

            ${breakpoint('large')} {
              width: 33.333%;
            }
          `;
      }
    }}
  }
`;

function BlurbSection({ title, items, backgroundColor }) {
  if (!items) {
    return null;
  }

  let columnCount = 2;

  if (items.length === 7) {
    columnCount = 3;
  } else if (items.length >= 6) {
    columnCount = 3;
  } else {
    columnCount = 2;
  }

  const invertContent =
    backgroundColor &&
    getContrast(backgroundColor, theme.palette.colors.primary) < 2.1;
  // getContrast(backgroundColor, theme.palette.text.primary) < 2.1;

  return (
    <Root style={backgroundColor ? { backgroundColor } : {}}>
      <GridContainer>
        <Content inverted={invertContent}>
          <LogoTag
            color={backgroundColor && lighten(0.15, backgroundColor)}
            style={{ marginBottom: '2rem' }}
          />

          {title && (
            <Col>
              <SectionTitle as="h3">{title}</SectionTitle>
            </Col>
          )}
        </Content>
        <Columns count={columnCount} rowCount={3} itemHeight={282}>
          {items?.map((blurb, index) => {
            const { image, content, button } = blurb;
            return (
              <Column
                // eslint-disable-next-line react/no-array-index-key
                key={`blurb-${index}`}
              >
                <ImageBoxItem
                  image={image?.localFile}
                  title={content.title}
                  // text={content.text}
                  subText={content.subText}
                  link={button.link}
                  // button="button"
                />
              </Column>
            );
          })}
        </Columns>
      </GridContainer>
    </Root>
  );
}

BlurbSection.propTypes = {
  title: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  backgroundColor: PropTypes.string,
};

BlurbSection.defaultProps = {
  title: null,
  backgroundColor: null,
};

export default BlurbSection;
