/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import Row from '../../components/Row';
import Col from '../../components/Col';
import { GridContainer } from '../../components/Grid';
import PackageItem from '../../components/PackageItem';

import { SectionTitle } from '../../utils/GlobalFormatting';
import { breakpoint } from '../../theme';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: ${rem(60)};
  padding-bottom: ${rem(60)};
  margin: ${rem(0)} auto 0;
  border-top: 1px solid ${(props) => props.theme.palette.colors.greyLight};
`;

const Title = styled(SectionTitle)`
  /* color: inherit; */
`;

// const Children = styled.div`
//   > :last-child {
//     margin-bottom: 0;
//   }
// `;

const Actions = styled.div`
  display: block;
  margin-top: ${rem(30)};
`;

const Packages = styled(Row)`
  width: 100%;
  margin-top: ${rem(18)};
`;

const PackageCol = styled(Col)`
  width: 100%;

  ${breakpoint('mediumlarge')} {
    width: 33.333%;
  }

  ${breakpoint('large')} {
    width: 33.333%;
  }
`;

function PackagesSection({ title, actions, items }) {
  const { t } = useTranslation();

  return (
    <Root>
      <GridContainer>
        <Row style={{ width: '100%', maxWidth: rem(875) }}>
          <Col>
            <h5>{t('packagesTitle')}</h5>
            <Title>{title}</Title>
          </Col>
        </Row>
        {items && (
          <Packages>
            {items?.map((item) => {
              return (
                <PackageCol key={item.id}>
                  <PackageItem
                    image={item.featuredImage?.node}
                    title={item.title}
                    text={item.excerpt}
                    subText={item.excerpt}
                    button={{ link: item.uri, text: t('readMore') }}
                  />
                </PackageCol>
              );
            })}
          </Packages>
        )}
        {actions && <Actions>{actions}</Actions>}
      </GridContainer>
    </Root>
  );
}

// <Row style={{ textAlign: 'center', marginTop: rem(25) }}>
//           <Col>
//             <Button link={packagesArchive}>{t('morePackages')}</Button>
//           </Col>
//         </Row>

PackagesSection.propTypes = {
  title: PropTypes.node.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.node,
};

PackagesSection.defaultProps = {
  items: null,
  actions: null,
};

export default PackagesSection;
