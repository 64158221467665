import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const Root = styled.div`
  position: relative;
  display: block;
  height: 0;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 */
  margin-bottom: 2rem;

  object,
  embed,
  iframe,
  video {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
`;

function ResponsiveEmbed({ children }) {
  return <Root>{children}</Root>;
}

ResponsiveEmbed.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default ResponsiveEmbed;
