import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import styled from 'styled-components';

import Row from '../../components/Row';
import Col from '../../components/Col';
import ResponsiveEmbed from '../../components/ResponsiveEmbed';

import { breakpoint } from '../../theme';
import { SectionTitle } from '../../utils/GlobalFormatting';

// import LinkUtil from '../../utils/link';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: 0 auto ${rem(40)};

  ${breakpoint('large')} {
    margin-bottom: ${rem(50)};
  }
`;

const Content = styled(Row)`
  position: relative;
  display: flex;
  flex-flow: row wrap;
`;

const Video = styled(Col)`
  margin: 0 auto ${rem(20)};
  width: 100%;

  ${breakpoint('mediumlarge')} {
    width: 85%;
  }

  ${breakpoint('large')} {
    width: 70%;
  }
`;

function VideoSection({ data }) {
  const { title, embed_code: embedCode, animated } = data;
  const salProps = {
    'data-sal': 'slide-up',
    'data-sal-duration': '450',
    'data-sal-delay': '25',
    'data-sal-easing': 'easeOutExpo',
  };

  return (
    <Container>
      <Content
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(animated ? salProps : undefined)}
      >
        {title && (
          <Col>
            <SectionTitle as="h3">{title}</SectionTitle>
          </Col>
        )}
        {embedCode && (
          <Video>
            <ResponsiveEmbed>
              <span
                dangerouslySetInnerHTML={{ __html: embedCode }} // eslint-disable-line react/no-danger
              />
            </ResponsiveEmbed>
          </Video>
        )}
      </Content>
    </Container>
  );
}

VideoSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  // content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  // button: PropTypes.shape({
  //   link: PropTypes.object,
  //   label: PropTypes.string,
  // }),
  animated: PropTypes.bool,
};

VideoSection.defaultProps = {
  animated: true,
};

export default VideoSection;
