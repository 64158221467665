import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';

import { breakpoint } from '../../theme';

import Slice from '../Slice';

const Section = styled.div`
  /* margin-bottom: ${rem(35)};

  ${breakpoint('large')} {
    margin-bottom: ${rem(50)};
  }

  &:last-child {
    margin-bottom: 0;
  } */
`;

function SliceZone({ slices, additionals }) {
  return slices?.map((slice, index) => (
    <Slice
      key={index} // eslint-disable-line react/no-array-index-key
      slice={slice}
      wrapper={<Section />}
      additionals={additionals}
    />
  ));
}

SliceZone.propTypes = {
  slices: PropTypes.arrayOf(PropTypes.object),
  additionals: PropTypes.oneOfType([PropTypes.object]),
};

SliceZone.defaultProps = {
  additionals: undefined,
};

export default SliceZone;
