import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import styled from 'styled-components';
import { getImage, getSrc } from 'gatsby-plugin-image';

import Row from '../../components/Row';
import Col from '../../components/Col';
import Image from '../../components/Image';
import { SectionTitle } from '../../utils/GlobalFormatting';

import { breakpoint } from '../../theme';

// import LinkUtil from '../../utils/link';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: 0 auto ${rem(40)};

  ${breakpoint('large')} {
    margin-bottom: ${rem(50)};
  }
`;

const Content = styled(Row)`
  position: relative;
  display: flex;
  flex-flow: row wrap;
`;

const Item = styled(Col)`
  margin: 0 0 ${rem(20)};

  ${breakpoint('mediumlarge')} {
    width: 50%;
  }
`;

function ImageGallery({ data }) {
  const { title, gallery: images } = data;

  if (!images) {
    return null;
  }

  return (
    <Container>
      <Content>
        {title && (
          <Col>
            <SectionTitle as="h3">{title}</SectionTitle>
          </Col>
        )}
        {images.map((image) => {
          const itemImage = getImage(image?.localFile);
          const src = getSrc(image?.localFile);
          return (
            itemImage && (
              <Item key={image.id}>
                <Image
                  image={itemImage}
                  fallback={{
                    url: src,
                    alt: image.altText,
                  }}
                  alt={image.altText}
                />
              </Item>
            )
          );
        })}
      </Content>
    </Container>
  );
}

ImageGallery.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  // content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  // button: PropTypes.shape({
  //   link: PropTypes.object,
  //   label: PropTypes.string,
  // }),
};

export default ImageGallery;
