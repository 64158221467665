/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import styled from 'styled-components';
import { getImage, getSrc } from 'gatsby-plugin-image';

import Row from '../../components/Row';
import Col from '../../components/Col';
import { SectionTitle } from '../../utils/GlobalFormatting';
import Image from '../../components/Image';
import Link from '../../components/Link';

import { breakpoint } from '../../theme';

// import LinkUtil from '../../utils/link';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: 0 auto ${rem(40)};

  ${breakpoint('large')} {
    margin-bottom: ${rem(50)};
  }
`;

const Content = styled(Row)`
  position: relative;
  display: flex;
  flex-flow: row wrap;
`;

const Item = styled(Col)`
  margin: 0 0 ${rem(20)};

  ${breakpoint('mediumlarge')} {
    width: 50%;
  }
`;

const Inner = styled.div`
  display: block;
  padding: 1.3rem 1.2rem;
`;

const Certification = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.palette.colors.sixt};
  color: ${(props) => props.theme.palette.colors.white};
`;

const Title = styled.h3`
  display: block;
  width: 100%;
  margin-bottom: ${rem(12)};
  font-size: ${rem(20)};
  font-weight: ${({ theme }) => theme.typography.weights.heading.normal};
  font-family: ${({ theme }) => theme.typography.fonts.heading};

  ${breakpoint('large')} {
    font-size: ${rem(22)};
  }
`;

const Thumbnail = styled.div`
  display: block;
  width: 100%;
`;

const ThumbnailPlaceholder = styled.div`
  display: block;
  width: 30%;
  max-width: ${rem(160)};
  background-color: ${(props) => props.theme.palette.colors.grey};
  overflow: hidden;
`;

const Description = styled.div`
  display: block;
  width: 100%;
  margin: 0 0 ${rem(30)} 0;
  font-size: ${rem(15)};

  ${breakpoint('large')} {
    font-size: ${rem(16)};
    margin-bottom: ${rem(40)};
  }
`;

const Button = styled(Link)`
  display: inline-block;
  cursor: pointer;
  border-radius: ${(props) => props.theme.components.button.radius};
  padding: ${rem(7)} ${rem(20)};
  appearance: none;
  user-select: none;
  font-size: ${rem(15)};
  font-family: ${(props) => props.theme.typography.fonts.button};
  font-weight: ${(props) => props.theme.typography.weights.button.bold};
  text-transform: ${(props) => props.theme.typography.button.textTransform};
  letter-spacing: ${(props) => props.theme.typography.button.letterSpacing};
  transition: ${(props) =>
    `all ${props.theme.global.transition.duration} ${props.theme.global.transition.timing}`};
  background-color: ${(props) => props.theme.palette.colors.primary};

  color: ${(props) => props.theme.palette.colors.white};

  border: none;

  /* cubic-bezier(0.78, 0.14, 0.15, 0.86) */

  &:hover {
    transform: scale(1.03);
    color: ${(props) => props.theme.palette.colors.white};
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: gray;
  }
`;

function CertificationsSection({ data }) {
  const { title, certifications: items } = data;

  return (
    <Container>
      <Content>
        {title && (
          <Col>
            <SectionTitle as="h3">{title}</SectionTitle>
          </Col>
        )}
        {items?.map((item) => {
          const itemImage = getImage(item.image?.localFile);
          const src = getSrc(item.image?.localFile);
          return (
            <Item key={item.name}>
              <Certification>
                <ThumbnailPlaceholder>
                  <Thumbnail>
                    {itemImage && (
                      <Image
                        image={itemImage}
                        fallback={{
                          url: src,
                          alt: item.image.altText,
                        }}
                        alt={item.image.altText}
                      />
                    )}
                  </Thumbnail>
                </ThumbnailPlaceholder>
                <Inner>
                  <Title>{item.name}</Title>
                  <Description>{item.description}</Description>
                  <Button to={item.file.source_url}>Ladda ned</Button>
                </Inner>
              </Certification>
            </Item>
          );
        })}
      </Content>
    </Container>
  );
}

CertificationsSection.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  // content: PropTypes.oneOfType([PropTypes.object]).isRequired,
  // button: PropTypes.shape({
  //   link: PropTypes.object,
  //   label: PropTypes.string,
  // }),
};

export default CertificationsSection;
