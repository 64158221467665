import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import styled from 'styled-components';

// import { GridContainer } from '../../components/Grid';
import Row from '../../components/Row';
import Col from '../../components/Col';
import LogoTag from '../../components/LogoTag';
// import Image from '../../components/Image';
import { GridContainer } from '../../components/Grid';

import { SectionTitle, StyledContent } from '../../utils/GlobalFormatting';

import { breakpoint, colors } from '../../theme';

const Root = styled(Row)`
  position: relative;
  width: 100%;
  margin: ${rem(0)} auto 0;
  flex-flow: initial;
  display: block;
  padding-top: ${rem(50)};
  /* padding-bottom: ${rem(50)}; */

  ${breakpoint('mediumlarge')} {
    display: flex;
    padding-top: ${rem(64)};
    /* padding-bottom: ${rem(64)}; */
  }

  ${breakpoint('large')} {
    padding-top: ${rem(70)};
    /* padding-bottom: ${rem(70)}; */
  }
`;

const ContentCol = styled(Col)`
  position: relative;
  width: auto;
  flex-grow: 1;
  margin-bottom: ${rem(30)};

  /* ${breakpoint('mediumlarge')} {
    width: 50%;
  }

  ${breakpoint('large')} {
    width: 70%;
  } */
`;

const SidebarCol = styled(Col)`
  position: relative;
  width: auto;

  /* ${breakpoint('mediumlarge')} {
    width: 50%;
  }

  ${breakpoint('large')} {
    width: 40%;
  } */
`;

const Sidebar = styled.aside`
  position: relative;
  background-color: ${(props) => props.theme.palette.colors.greyLight};
  padding: 2.85rem 2.5rem;
  border: ${rem(8)} solid ${(props) => props.theme.palette.background.content};
  box-shadow: 0px 0px 0px 1px ${(props) => props.theme.palette.colors.greyLight};
  width: ${rem(385)};
  max-width: 100%;
  margin: auto;

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${(props) => props.theme.palette.colors.primary};
  }

  h2,
  h3 {
    margin-bottom: 1.5rem;
  }

  h4,
  h5,
  h6 {
    margin-top: 1.5rem;
  }
`;

const SidebarInner = styled.div`
  position: relative;
  padding: 0;
`;

const Tag = styled.div`
  width: 100%;
  /* position: absolute;
  bottom: 0;
  left: 0;
  right: 0; */
  margin: ${rem(40)} auto;
  text-align: center;
`;

function SidebarSection({ title, children, reverse, sidebar, animated }) {
  const salProps = {
    'data-sal': 'slide-up',
    'data-sal-duration': '450',
    'data-sal-delay': '25',
    'data-sal-easing': 'easeOutExpo',
  };

  return (
    <GridContainer>
      <Root
        style={
          reverse
            ? {
                flexDirection: 'row-reverse', // Only reverse on desktop
              }
            : {}
        }
      >
        <ContentCol>
          <StyledContent>
            {title && <SectionTitle>{title}</SectionTitle>}
            <div
              dangerouslySetInnerHTML={{ __html: children }} // eslint-disable-line react/no-danger
            />
          </StyledContent>
        </ContentCol>
        <SidebarCol>
          <Sidebar
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(animated ? salProps : undefined)}
          >
            <SidebarInner
              dangerouslySetInnerHTML={{ __html: sidebar }} // eslint-disable-line react/no-danger
            />
          </Sidebar>
        </SidebarCol>
      </Root>
      <Tag>
        <LogoTag color={colors.greyLight} />
      </Tag>
    </GridContainer>
  );
}

SidebarSection.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  sidebar: PropTypes.node,
  button: PropTypes.shape({
    link: PropTypes.string,
    label: PropTypes.string,
  }),
  reverse: PropTypes.bool,
  image: PropTypes.oneOfType([PropTypes.object]),
  animated: PropTypes.bool,
};

SidebarSection.defaultProps = {
  title: null,
  children: null,
  sidebar: null,
  button: {
    link: null,
    label: null,
  },
  reverse: false,
  image: null,
  animated: true,
};

export default SidebarSection;
