import React from 'react';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';

import Row from '../../components/Row';
import Col from '../../components/Col';
import LogoTag from '../../components/LogoTag';
import TestimonialItem from '../../components/TestimonialItem';

import { SectionTitle } from '../../utils/GlobalFormatting';

import { breakpoint } from '../../theme';

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: ${rem(350)};
  padding-top: ${rem(60)};
  padding-bottom: ${rem(60)};
  margin: ${rem(0)} auto 0;

  ${breakpoint('mediumlarge')} {
    min-height: ${rem(450)};
  }

  ${breakpoint('large')} {
    min-height: ${rem(550)};
  }
`;

const Testimonials = styled(Section)`
  text-align: center;
  width: 100%;
  /* background-color: ${(props) => props.theme.palette.colors.greyLight}; */
  border-bottom: 1px solid ${(props) => props.theme.palette.colors.greyLight};

  ${Row} {
    width: 100%;
    max-width: ${rem(1000)};
  }

  .slider-control-bottomcenter {
    position: relative !important;
    margin: ${rem(24)} auto 0;
    transform: none !important;
    left: auto !important;
    bottom: auto !important;
  }

  .carousel-dots {
    justify-content: center;
  }

  .carousel-dot {
    padding: 0.4rem 0.3rem;
    margin: 0 !important;
  }
`;

function TestimonialSection({ title, items }) {
  return (
    <Testimonials>
      <Row>
        <Col>
          <LogoTag />
          <SectionTitle big>{title}</SectionTitle>
          <div style={{ marginTop: rem(32) }}>
            <Carousel
              transitionMode="fade"
              speed={710}
              autoplay
              autoplayInterval={3500}
              wrapAround
              defaultControlsConfig={{
                // nextButtonClassName: PropTypes.string,
                nextButtonStyle: {
                  display: 'none',
                },
                // nextButtonText: PropTypes.string,
                // prevButtonClassName: PropTypes.string,
                prevButtonStyle: {
                  display: 'none',
                },
                // prevButtonText: PropTypes.string,
                pagingDotsContainerClassName: 'carousel-dots',
                pagingDotsClassName: 'carousel-dot',
                pagingDotsStyle: {
                  fill: '#000',
                },
              }}
            >
              {items.edges?.map(({ node: item }) => {
                const { title: itemTitle, content } = item;
                return (
                  <TestimonialItem key={item.id} title={itemTitle}>
                    {content}
                  </TestimonialItem>
                );
              })}
            </Carousel>
          </div>
        </Col>
      </Row>
    </Testimonials>
  );
}

TestimonialSection.propTypes = {
  title: PropTypes.node,
  items: PropTypes.oneOfType([PropTypes.object]),
};

TestimonialSection.defaultProps = {
  title: null,
  items: [],
};

export default TestimonialSection;
