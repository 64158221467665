import React from 'react';
import PropTypes from 'prop-types';

import {
  // HeroImage,
  // HeroVideo,
  ColumnWithButton,
  ImageGallery,
  ArticlesSection,
  VideoSection,
  ColumnsSection,
  ImageSection,
  BlurbSection,
  SplitSection,
  PackagesSection,
  PackagesArchiveSection,
  SidebarSection,
  TestimonialSection,
} from '../../slices';

function Slice({ slice, additionals }) {
  // eslint-disable-next-line no-underscore-dangle
  const type = slice.type || slice.__typename;
  switch (type) {
    case 'WpPage_Sections_Sections_Gallery':
      return <ImageGallery data={slice} />;
    // case 'image_item':
    //   return <HeroImage data={slice} />;
    // case 'video_item':
    //   return <HeroVideo data={slice} />;
    case 'column_with_button':
      return <ColumnWithButton data={slice} />;

    case 'WpPage_Sections_Sections_Blurbsection':
      return (
        <BlurbSection
          title={slice.title}
          items={slice.blurbs}
          backgroundColor={slice.backgroundColor}
        >
          {slice.content}
        </BlurbSection>
      );
    case 'WpPage_Sections_Sections_Imagebgsection':
      return (
        <ImageSection image={slice.image?.localFile} title={slice.title}>
          {slice.content}
        </ImageSection>
      );
    case 'articles':
      return (
        <ArticlesSection data={slice} allArticles={additionals?.postItems} />
      );
    case 'WpPage_Sections_Sections_Video':
      return <VideoSection data={slice} />;
    case 'WpPage_Sections_Sections_Columns':
      return (
        <ColumnsSection
          title={slice.title}
          columns={slice.columns}
          backgroundColor={slice.backgroundColor}
          centered={slice.centeredColumn}
          withIntro={slice.withIntro}
          content={slice.content}
        />
      );
    case 'WpPage_Sections_Sections_Splitsection':
      return (
        <SplitSection
          image={slice.image}
          title={slice.title}
          reverse={slice.reverse}
          button={slice.button}
        >
          {slice.content}
        </SplitSection>
      );
    case 'WpTeaser':
      return (
        <SplitSection
          image={slice.teaserData.teaserbild}
          title={slice.title}
          reverse={slice.teaserData.teaserbildPosition !== 'left'}
          button={
            slice.teaserData.teaserknapp?.[0] && {
              link:
                slice.teaserData.teaserknapp?.[0]?.externLank ||
                slice.teaserData.teaserknapp?.[0]?.lank?.uri,
              label: slice.teaserData.teaserknapp?.[0].text,
            }
          }
        >
          {slice.content}
          {/* slice.teaserData.teaserknapp &&
            slice.teaserData.teaserknapp?.map((button, index) => {
              console.log(button);
              return (
                <div key={`teaserknapp-${index}`}>
                  <p>{button.text}</p>
                </div>
              );
            }) */}
        </SplitSection>
      );
    case 'WpPage_Sections_Sections_Teasers':
      return null;
    case 'WpPage_Sections_Sections_Sidebar':
      return (
        <SidebarSection title={slice.title} sidebar={slice.sidebar}>
          {slice.content}
        </SidebarSection>
      );
    case 'WpPage_Sections_Sections_Packages':
      return (
        <PackagesSection
          title={slice.title}
          backgroundColor={slice.backgroundColor}
          items={slice.packages || additionals?.allPackages}
        />
      );
    case 'WpPage_Sections_Sections_Packagearchive':
      return (
        <PackagesArchiveSection
          title={slice.title}
          backgroundColor={slice.backgroundColor}
          // items={additionals?.allPackages}
          categories={slice.packagecategories}
        />
      );
    case 'Testimonials':
      return <TestimonialSection title={slice.title} items={slice?.items} />;
    default:
      return null;
  }
}

Slice.propTypes = {
  slice: PropTypes.oneOfType([PropTypes.object]).isRequired,
  additionals: PropTypes.oneOfType([PropTypes.object]),
};

Slice.defaultProps = {
  additionals: undefined,
};

export default Slice;
