/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import { rem, rgba } from 'polished';
import { StaticQuery, graphql } from 'gatsby';
import { BgImage } from 'gbimage-bridge';

import styled from 'styled-components';

import { SectionTitle } from '../../utils/GlobalFormatting';
import { breakpoint } from '../../theme';

const Root = styled(BgImage)`
  position: relative;
  display: inline-table;
  width: 100%;
  /* background-image: url(${(props) => props.fallbackBackground}); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  text-align: center;
  min-height: ${rem(350)};
  padding: ${rem(40)} ${(props) => props.theme.spacing.columnGutter};

  background-color: ${(props) => props.theme.palette.colors.greyDark};
  color: ${(props) => props.theme.palette.colors.white};

  ${breakpoint('medium')} {
    min-height: ${rem(400)};
  }

  ${breakpoint('mediumlarge')} {
    min-height: ${rem(460)};
  }

  ${breakpoint('large')} {
    min-height: ${rem(550)};
    padding-top: ${rem(70)};
    padding-bottom: ${rem(70)};
  }
`;

const Title = styled(SectionTitle)`
  color: inherit;
`;

const Container = styled.div`
  display: table-cell;
  vertical-align: middle;
  position: relative;
  margin: 0 auto;
  padding: 0 ${(props) => props.theme.spacing.columnGutter};
`;

const Content = styled.div`
  width: 100%;
  margin: auto;
  max-width: ${(props) => props.theme.grid.maxWidthText};

  p {
    color: ${(props) => props.theme.palette.colors.greyLight};
  }
`;

const Children = styled.div`
  > :last-child {
    margin-bottom: 0;
  }
`;

const Actions = styled.div`
  display: block;
  margin-top: ${rem(30)};
`;

const Tint = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${(props) => rgba(props.theme.palette.colors.black, 0.45)};
`;

const query = graphql`
  {
    fileName: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 75, layout: FULL_WIDTH)
      }
    }
  }
`;

function ImageSection({ title, image, actions, children, animated }) {
  const salProps = {
    'data-sal': 'slide-up',
    'data-sal-duration': '450',
    'data-sal-delay': '25',
    'data-sal-easing': 'easeOutExpo',
  };

  return (
    <StaticQuery
      query={`${query}`} // String hack needed to fix https://github.com/birkir/gatsby-source-prismic-graphql/issues/70
    >
      {({ fileName: fallbackBackground }) => {
        const heroImage = getImage(image || fallbackBackground);

        return (
          <Root Tag="div" image={heroImage}>
            <Tint />
            <Container>
              <Content {...(animated ? salProps : undefined)}>
                <Title>{title}</Title>
                <Children dangerouslySetInnerHTML={{ __html: children }} />
                {actions && <Actions>{actions}</Actions>}
              </Content>
            </Container>
          </Root>
        );
      }}
    </StaticQuery>
  );
}

ImageSection.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]).isRequired,
  actions: PropTypes.node,
  animated: PropTypes.bool,
};

ImageSection.defaultProps = {
  children: null,
  actions: null,
  animated: true,
};

export default ImageSection;
