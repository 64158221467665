import React from 'react';
import PropTypes from 'prop-types';
import { em, rem } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import styled from 'styled-components';

import { breakpoint } from '../../theme';

const Root = styled.div`
  width: 100%;
  font-size: ${rem(22)};

  ${breakpoint('mediumlarge')} {
    font-size: ${rem(25)};
  }

  ${breakpoint('large')} {
    font-size: ${rem(28)};
  }
`;

const Content = styled.div`
  position: relative;
  display: block;
  width: 100%;
  font-size: 1em;
  text-align: center;
  font-family: ${(props) => props.theme.typography.fonts.alt};
  font-weight: ${(props) => props.theme.typography.weights.alt.normal};
  color: ${(props) => props.theme.palette.text.primary};
  /* padding: 1.1em 1.4em;

  ${breakpoint('large')} {
    padding: 1.25em 1.6875em;
  } */

  p {
    line-height: 1.6;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Cite = styled.span`
  display: block;
  font-family: Arial, sans-serif;
  color: #333;
  font-size: 3.8em;
  line-height: 1;
  height: 0.6em;
  overflow: hidden;
`;

const Meta = styled.div`
  position: relative;
  display: block;
  width: 100%;
  font-size: ${rem(18)};
  text-align: center;
  margin-top: 0.9em;

  ${breakpoint('mediumlarge')} {
    font-size: ${rem(20)};
  }
`;

const Rating = styled.div`
  position: relative;
  display: block;
  width: 100%;
`;

const Title = styled.span`
  display: block;
  width: 100%;
  font-size: 1em;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.typography.fonts.primary};
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: ${em(15)};
  vertical-align: middle;
  margin-left: 0.15em;
`;

const Quote = styled.div``;

function TestimonialItem(props) {
  const { children, title, rating, icon } = props;

  return (
    <Root>
      <Content>
        {icon && <Cite>“</Cite>}
        <Quote
          dangerouslySetInnerHTML={{ __html: children }} // eslint-disable-line react/no-danger
        />
        <Meta>
          <Title>{title}</Title>
          {rating && (
            <Rating>
              {[...Array(rating)].map((e, i) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Icon key={`rating-star${i}`} icon={faStar} color="#f5bc13" />
                );
              })}
            </Rating>
          )}
        </Meta>
      </Content>
    </Root>
  );
}

TestimonialItem.propTypes = {
  children: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  rating: PropTypes.number,
  icon: PropTypes.bool,
};

TestimonialItem.defaultProps = {
  rating: null,
  icon: false,
};

export default TestimonialItem;
